import React, { FC } from 'react';

import Logo from 'layout/Logo';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';

import { IPropsHomeBaseInfo } from './models';

import './HomeBaseInfo.scss';

const HomeBaseInfo: FC<IPropsHomeBaseInfo> = ({ brandSettings, homeBaseInfo }) => (
  <div data-testid="HomeBaseInfo" className="home-base-info">
    <div className="home-base-info__logo">
      <Logo brandSettings={brandSettings} withoutLink />
    </div>
    <h1 className="home-base-info__title">{homeBaseInfo[0].title}</h1>
    <DangerouslySetInnerHtml
      className="home-base-info__description"
      html={homeBaseInfo[0].description}
    />
  </div>
);
export default HomeBaseInfo;
