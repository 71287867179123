import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import CountriesList from 'components/sections/CountriesList';
import HomeBaseInfo from 'components/sections/HomeBaseInfo';

import { IPropsHomePage } from './models';

import './HomePage.scss';

const HomePage: FC<IPropsHomePage> = ({ data: { pageData, brandSettings }, pageContext }) => {
  const { seo, urls, homeBaseInfo, countriesList } = pageData;
  const { lang } = pageContext;

  return (
    <Layout
      data-testid="HomePage"
      isHideHeader
      seo={seo}
      lang={lang}
      urls={urls}
      className="home-page"
    >
      <Container>
        <HomeBaseInfo homeBaseInfo={homeBaseInfo} brandSettings={brandSettings} />
        <CountriesList countriesList={countriesList} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String) {
    pageData: homePage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentHomePage
    }
    brandSettings: brandSettings(lang: { eq: $lang }) {
      ...FragmentBrandSettings
    }
  }
`;

export default HomePage;
