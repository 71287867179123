import React, { FC } from 'react';
import { Link } from 'gatsby';

import GatsbyImage from 'components/helpers/GatsbyImage';

import { IPropsCountriesList } from './models';

import './CountriesList.scss';

const CountriesList: FC<IPropsCountriesList> = ({ countriesList }) => (
  <div data-testid="CountriesList" className="countries-list">
    <div className="countries-list__container">
      {countriesList?.[0]?.items.length
        ? countriesList[0].items.map(({ title, image, imageSecond, link }) => (
            <article key={title} className="countries-list__item">
              <Link to={link[0].url} className="countries-list__item-link">
                <div className="countries-list__item-image">
                  <GatsbyImage image={image} objectFit="contain" />
                  {imageSecond ? <GatsbyImage image={imageSecond} objectFit="contain" /> : null}
                </div>

                <div className="countries-list__item-title">{title}</div>
              </Link>
            </article>
          ))
        : null}
    </div>
  </div>
);
export default CountriesList;
